<template>
  <div
    class="components_task_side_bar_teach_pages_make_fun_page bbox justify-space-between d-flex align-center"
  >
    <div class="title_group d-flex align-center">
      <div class="icon"
        :class="[type]"
      ></div>
      <div class="title">{{title}}</div>
    </div>
    <div class="button"
      v-if="edit && open_add"
      @click="doClick"
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '视频'
    },
    type: {
      type: String,
      default: 'video'
    },
    edit: {
      type: Boolean,
      default: false,
    },
    use: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    open_add: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    doClick(){
      this.$emit('handleClick', this.type);
    }
  }
}
</script>
<style lang="scss" scoped>
@mixin icon($type){
  &.#{$type}{
    background-image: url(~@/assets/images/teach/#{$type}.png);
  }
}
.components_task_side_bar_teach_pages_make_fun_page{
  width: 100%;
  height: 36px;
  background: #F6F6F6;
  border-radius: 4px;
  padding: 0 10px;
  .icon{
    @include background;
    @include icon(video);
    @include icon(file);
    @include icon(hyperlink);
    @include icon(intro);
    margin-right: 10px;
    width: 16px;
    height: 16px;
  }
  .title{
    font-size: 16px;
    color: #333333;
    line-height: 21px;
  }
  .button{
    @include background;
    background-image: url(~@/assets/images/teach/add.png);
    width: 24px;
    height: 24px;
  }
}
</style>
